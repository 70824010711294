<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
  >
    <v-card>
      <v-card-title>
        Выберите версию для утверждения
      </v-card-title>
      <v-divider />
      
      <v-card-subtitle>
        <VersionSelection
          :filter="filter"
          :selected="selected"
          @selectVersion="onVersionSelected"
          @versionsCount="checkAvailableDialog"
        ></VersionSelection>
        <v-form ref="form">
        <v-layout row wrap>
            <v-flex offset-xs0 xs4><jet-input :disabled="false" :required="true" label="Дата приказа" type="date" v-model="orderDt"/></v-flex>
            <v-flex offset-xs0 xs4><jet-input :disabled="false" :required="true" label="Номер приказа" type="string" :max-length="20" v-model="orderNum"/></v-flex>
            <v-flex offset-xs0 xs4><v-file-input label="Приказ" prepend-icon="mdi-file-document-outline" :rules="fileRules" @change="on_file_change"></v-file-input></v-flex>
        </v-layout>
        </v-form>
      </v-card-subtitle>
      <v-card-text class="pt-4">
        <p>Укажите дату утверждения версии маршрута</p>
        <v-date-picker
          v-model="date"
          full-width
          :show-current="true"
          :first-day-of-week="1"
          :weekday-format="dayOfWeekToFormat"
        ></v-date-picker>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-row dense>
          <v-col>
            <v-btn
              outlined
              color="red"
              @click="dialog = false"
              :disabled="loading"
            >
               Отмена
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="approveQuery"
              :loading="loading"
              :disabled="!date"
            >
              Утвердить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <component
      :is="component"
      :messages="messages"
      :collection="this.collection"
      :routeId="this.selected.vcroutesId"
      :verId="this.selected.vcroutesVerid"
      :date="this.date"
      :userId="this.selected._sec_attrsUserid"
      :tenantId="this.selected._sec_attrsTenantid"
      :selected="this.selected"
      :order="{dt: this.orderDt, num: this.orderNum, file: this.orderFile}"
      @onApply="approveQuery"
    ></component>
  </v-dialog>
</template>

<script>
import {dayOfWeekToFormat} from "@/services/JetDate";

const PROJECT_STATUS_GUID = 'ed4762ee-ca91-4b52-89e8-1176fb0a296e';

import Vue from "vue";

import VersionSelection from '@/components/actions/RoutesSettings/VersionSelection';
import JetInput from '@/components/editors/JetInput';

export default {
  name: "ApproveRouteVersion",
  components: {
    VersionSelection, JetInput
  },
  props: {
    collection: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    orderDt: null,
    orderNum: null,
    orderFile: null,
    c: null,
    dialog: true,
    loading: false,
    component: null,
    item: null,
    block: false,
    selected: null,
    messages: [],
    components: [
      {name: 'AcceptanceErrors',},
      {name: 'AcceptanceWarnings',}
    ],
    dayOfWeekToFormat: () => '',
    filter: '',
    fileRules: [
        v => !!v || 'Обязательное поле'
    ]
  }),
  created() {
    this.dayOfWeekToFormat = dayOfWeekToFormat;
    this.selected = this.collection.master.selected;

    if (!this.selected) {
      jet.msg({
        text: 'Необходимо выбрать запись!',
        color: 'warning'
      });
      return;
    }
    
    this.filter = `and(eq(field(".verID"),param("${this.selected.vcroutesVerid}","id")),eq(field(".verStatus"),param("${PROJECT_STATUS_GUID}","id"))`;
  },
  methods: {
    on_file_change(e) {
        const file = e;
        if ( !(!!file) ) {
            this.orderFile = Object.assign({}, {
                          id: null,
                          name: null,
                          mimeType: null,
                          size: null,
                          value: null
                      });
            return;
        }
        const reader = new FileReader(),
            self = this;

        reader.onload = _e => {
            const data = _e.target.result;
            const mimeType = data.match(/data:(.+);/)[1];
            const commaIndex = data.indexOf(',');
            self.orderFile = {
                id: null,
                name: file.name,
                mimeType: mimeType,
                size: file.size,
                value: data.substr(commaIndex + 1)
            };
            if ( !!self.value && !!self.selected ) {
                this.save({id: self.selected, msg: null, attach: self.value});
                this.msg = null;
            }
        };
        reader.readAsDataURL(file);
    },
    async approveQuery(messages) {
      if ( !this.$refs.form.validate() ) {
        return;
      }
      let component = '';
      let {id: userId, tenant: tenantId} = this.$store.state.profile;
  
      let optionsQuery = {
        type: 'query',
        query: 'feb1b1ec-48b9-46d4-b59e-93d9ff3f1f09.aproveRoute',
        params: {
          in_routeId: this.selected.vcroutesId,
          in_verId: this.selected.vcroutesVerid,
          in_date: this.date,
          in_userId: userId,     //this.selected._sec_attrsUserid,
          in_tenantId: tenantId, //this.selected._sec_attrsTenantid,
          in_releaseScheduleId: '00000000-0000-0000-0000-000000000000',
          in_mode: 1
        }
      };
      try {
        const resp = await jet.http.post(optionsQuery);
        if (resp.error) {
          throw resp.error;
        }

        const arr = resp.result.data;
        for (var i = 0; i < arr.length; i++){
          var item = arr[i];
          (this.messages).push(JSON.parse(item[0]));
          
          if (this.messages[i].response.is_block) {
            component = this.components[0];
            break;
          } else {
            component = this.components[1];
          }
        }

        this.component = component.name;
        const checkImport = import(`./components/${this.component}`);
        //TODO: разобраться почему catch выбрасывается раньше, чем выводится сообщение об отсутсвии компонента
        if (!checkImport) {
          jet.msg({
            text: `Компонент ${this.component} не найден!`,
            color: 'warning'
          });
        } else {
          Vue.component(
            this.component,
            () => import('./components/' + this.component)
          );
        }

      } catch(e){
        console.error('ERR (approve)', e);
        jet.msg({
          text: `Не удалось утвердить версию маршрута:<br /><small>${ e.message }</small>`,
          color: 'warning'
        });
        return;
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
    onVersionSelected(item) {
      this.selected = item;
      this.version = this.selected.vcroutesVersion;
    },
    checkAvailableDialog(count) {
      if (count === 0) {
        this.dialog = false;
        jet.msg({
          text: 'Действие доступно только для статуса «Проект», у маршрута нет версий с таким статусом',
          color: 'warning'
        });
        return; 
      }
    },
  }
}
</script>

